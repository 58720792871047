<template>
  <a href="javascript:void(0)" v-if="imageUrls && imageUrls.length > 0" @click="showImages">Xem ảnh</a>
</template>

<script>
import {api as viewerApi} from "v-viewer";

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  name: "HandoverImage",
  data: () => ({

  }),
  computed: {
    imageUrls() {
      if (!this.item.images || this.item.images.length === 0) {
        return []
      }
      const images = this.item.images.split(';')
      return images.map(img => (`${process.env.VUE_APP_FILE_CDN_URL}/${img}`))
    }
  },
  methods: {
    showImages() {
      const index = 0
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index
        },
        images: this.imageUrls
      })
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
